import { render, staticRenderFns } from "./LandingVerificacionFacturasDeudor.vue?vue&type=template&id=19c7961c&scoped=true&class=white&"
import script from "./LandingVerificacionFacturasDeudor.vue?vue&type=script&lang=ts&"
export * from "./LandingVerificacionFacturasDeudor.vue?vue&type=script&lang=ts&"
import style0 from "./LandingVerificacionFacturasDeudor.vue?vue&type=style&index=0&id=19c7961c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19c7961c",
  null
  
)

export default component.exports